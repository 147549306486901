import { styled } from '@mui/material';
import { ModalType, ModalSize, ModalStyleType } from '../../../models/enum';

export const ModalBodyStyled = styled('div', {
  shouldForwardProp: (prop) => !['styleType'].includes(prop as string),
})(({ theme, size, type, styleType }) => ({
  backgroundColor: 'inherit',
  height: (() => {
    switch (size) {
      case ModalSize.FULLSCREEN:
        return styleType === ModalStyleType.mobileFilters
          ? 'auto'
          : `calc(100% - ${theme.spacings.unit(15)})`;
      default:
        return '';
    }
  })(),
  width: (() => {
    switch (size) {
      case ModalSize.FULLSCREEN:
        return '100vw';
      default:
        return '';
    }
  })(),
  color: (() => {
    switch (styleType) {
      case ModalStyleType.localizations:
      case ModalStyleType.reservedArea:
        return theme.colors.modalTexgtBlackOnWhite;
      default:
        return 'inherit';
    }
  })(),
  padding: (() => {
    switch (styleType) {
      case ModalStyleType.localizations:
      case ModalStyleType.reservedArea:
        return `0 ${theme.spacings.unit(24 / 4)} ${theme.spacings.unit(
          24 / 4
        )}`;
      case ModalStyleType.mobileFilters:
        return `0 ${theme.spacings.unit(6)} ${theme.spacings.unit(3)}`;
      default:
        return `${theme.spacings.unit(3)} ${theme.spacings.unit(6)}`;
    }
  })(),
  ...(type === ModalType.FULLSCREEN && {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
}));
