import { styled } from '@mui/material';
import { ModalSize, ModalStyleType } from '../../../models/enum';

export const ModalCenterContainer = styled('div', {
  shouldForwardProp: (prop) => !['styleType', 'size'].includes(prop as string),
})(({ theme, styleType, size }) => ({
  position: 'absolute',
  top: size === ModalSize.FULLSCREEN ? '0' : '50%',
  left: size === ModalSize.FULLSCREEN ? '0' : '50%',
  transform:
    size === ModalSize.FULLSCREEN
      ? 'translate(0%, 0%)'
      : 'translate(-50%, -50%)',
  backgroundColor: theme.colors.modalBackground,
  color: (() => {
    switch (styleType) {
      default:
        return theme.colors.black;
      case ModalStyleType.mobileFilters:
        return theme.colors.black;
    }
  })(),
}));
