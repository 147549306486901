import React, { ReactElement } from 'react';

import {
  Container,
  ModalFooterStyled,
  StyledDivider,
} from './ModalFooter.style';

export interface ModalFooterProps {
  children?: string | ReactElement | any;
  align?: 'start' | 'end' | 'center' | 'space-between';
  showDivider?: boolean;
}

const ModalFooter: React.FC<ModalFooterProps> = React.forwardRef(
  ({ children, align = 'center', showDivider = false }, ref) => {
    return (
      <Container>
        {showDivider ? <StyledDivider /> : null}
        <ModalFooterStyled ref={ref} align={align}>
          {children}
        </ModalFooterStyled>
      </Container>
    );
  }
);

export default ModalFooter;
