import React, { ReactElement } from 'react';
import { Modal as ModalMUI } from '@mui/material';

import { ModalCenterContainer } from './Modal.style';
import { ModalSize, ModalStyleType } from '../../../models/enum';

export interface ModalProps {
  show: boolean;
  onClose: () => void;
  styleType?: ModalStyleType;
  size?: ModalSize;
}

const Modal: React.FC<ModalProps> = ({
  show,
  children,
  onClose,
  styleType,
  size,
}) => {
  return (
    <ModalMUI open={show} onClose={onClose}>
      <ModalCenterContainer styleType={styleType} size={size}>
        {children as ReactElement}
      </ModalCenterContainer>
    </ModalMUI>
  );
};

export default Modal;
