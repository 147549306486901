import React from 'react';
import { ModalSize, ModalStyleType, ModalType } from '../../../models/enum';

import { ModalContainerStyled } from './ModalContainer.style';

interface IProps {
  type: ModalType;
  size: ModalSize;
  onClose: () => void;
  styleType: ModalStyleType;
}

const ModalContainer: React.FC<IProps> = React.forwardRef(
  ({ children, type, size, onClose, styleType }, ref) => {
    return (
      <ModalContainerStyled
        type={type}
        size={size}
        ref={ref}
        styleType={styleType}
        onClick={type === ModalType.FULLSCREEN ? onClose : null}
      >
        {children}
      </ModalContainerStyled>
    );
  }
);

export default ModalContainer;
