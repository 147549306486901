import { styled } from '@mui/material';
import { ModalStyleType } from '../../../models/enum';

export const ModalTitleStyled = styled('h3', {
  shouldForwardProp: (prop) => !['styleType'].includes(prop as string),
})(({ theme, styleType }) => {
  const reservedAreaStyle = {
    color: theme.colors.primary,
  };

  const filterStyle = {
    fontSize: theme.fonts.utility.size(13),
    fontWeight: 'normal',
  };

  const typeStyle = (): any => {
    switch (styleType) {
      case ModalStyleType.contactUs:
      default:
        return {};
      case ModalStyleType.localizations:
      case ModalStyleType.reservedArea:
        return reservedAreaStyle;
      case ModalStyleType.mobileFilters:
        return filterStyle;
    }
  };

  return {
    marginTop: '0',
    marginBottom: '0px',
    ...typeStyle(),
  };
});
