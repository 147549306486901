import React, { ReactElement } from 'react';
import { ModalType, ModalSize, ModalStyleType } from '../../../models/enum';

import { ModalBodyStyled } from './ModalBody.style';

export interface ModalBodyProps {
  children: ReactElement;
  type?: ModalType;
  size?: ModalSize;
  styleType?: ModalStyleType;
}

const ModalBody: React.FC<ModalBodyProps> = React.forwardRef(
  ({ children, type, size, styleType }, ref) => {
    return (
      <ModalBodyStyled size={size} type={type} ref={ref} styleType={styleType}>
        {children}
      </ModalBodyStyled>
    );
  }
);

export default ModalBody;
