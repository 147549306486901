import React, { ReactElement } from 'react';
import { ModalStyleType, ModalType } from '../../../models/enum';
import ModalTitle from '../modal-title/ModalTitle';

import {
  ModalHeaderStyled,
  CloseStyled,
  StyledDivider,
  Container,
} from './ModalHeader.style';

export interface ModalHeaderProps {
  children?: ReactElement | string;
  type?: ModalType;
  onClose: () => void;
  showDivider?: boolean;
  typeHeader?: 'content' | 'text';
  styleType?: ModalStyleType;
}

const ModalHeader: React.FC<ModalHeaderProps> = React.forwardRef(
  (
    {
      children,
      type,
      typeHeader = 'content',
      onClose,
      styleType,
      showDivider = false,
    },
    ref
  ) => {
    return (
      <Container type={type} ref={ref}>
        <ModalHeaderStyled>
          {typeHeader === 'content' ? (
            children
          ) : (
            <ModalTitle title={children}></ModalTitle>
          )}
          <CloseStyled
            type={type}
            styleType={styleType}
            onClick={() => {
              onClose();
            }}
          />
        </ModalHeaderStyled>
        {showDivider ? <StyledDivider /> : null}
      </Container>
    );
  }
);

export default ModalHeader;
