import { styled } from '@mui/material';
import React from 'react';
import { ModalSize, ModalStyleType, ModalType } from '../../../models/enum';

export const ModalContainerStyled = styled(
  React.forwardRef(({ type, ...props }: any, ref) => (
    <div {...props} ref={ref}></div>
  )),
  {
    shouldForwardProp: (prop) => !['styleType'].includes(prop as string),
  }
)(({ theme, type, size, styleType }) => ({
  backgroundColor: (() => {
    switch (styleType) {
      default:
      case ModalStyleType.contactUs:
        return theme.colors.background;
      case ModalStyleType.localizations:
      case ModalStyleType.reservedArea:
      case ModalStyleType.mobileFilters:
        return theme.colors.background;
    }
  })(),
  margin: '0 auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'relative',
  // margin: theme.spacings.unit(6),
  border: (() => {
    switch (styleType) {
      default:
      case ModalStyleType.contactUs:
      case ModalStyleType.localizations:
      case ModalStyleType.reservedArea:
      case ModalStyleType.mobileFilters:
        return 'none';
    }
  })(),
  boxShadow: theme.gradients.modal,
  maxHeight: (() => {
    switch (size) {
      case ModalSize.FULLSCREEN:
        return 'calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top))';
      case ModalSize.BIG:
        return `calc(100vh - ${theme.spacings.unit(40)})`;
      default:
        return `calc(100vh - ${theme.spacings.unit(40)});`;
    }
  })(),
  height: (() => {
    switch (size) {
      case ModalSize.FULLSCREEN:
        return '100vh';
      case ModalSize.BIG:
        return `calc(100vh - ${theme.spacings.unit(12)})`;
      default:
        return '';
    }
  })(),
  width: (() => {
    switch (size) {
      case ModalSize.FULLSCREEN:
        return '100vw';
      case ModalSize.BIG:
        return `calc(100vw - ${theme.spacings.unit(12)})`;
      case ModalSize.MEDIUM:
        return theme.spacings.unit(192);
      case ModalSize.SMALL:
      case ModalSize.INITIAL:
      default:
        return theme.spacings.unit(128);
    }
  })(),
  ...(type === ModalType.FULLSCREEN && {
    boxShadow: '0px 0px 0px',
    ...(styleType !== ModalStyleType.mobileFilters && {
      backgroundColor: 'unset',
      width: `calc(100vw - ${theme.spacings.unit(12)})`,
      height: `calc(100vh - ${theme.spacings.unit(12)})`,
    }),
  }),
  ...(size === ModalSize.FULLSCREEN && {
    [theme.breakpointsMediaQuery.down('md')]: {
      ...(styleType !== ModalStyleType.mobileFilters && {
        width: `calc(100vw - ${theme.spacings.unit(14)})`,
      }),
    },
  }),
  [theme.breakpointsMediaQuery.up('md')]: {
    width: (() => {
      switch (size) {
        case ModalSize.FULLSCREEN:
          return '100vw';
        case ModalSize.BIG:
          return `calc(100vw - ${theme.spacings.unit(12)})`;
        case ModalSize.MEDIUM:
          return theme.spacings.unit(192);
        case ModalSize.SMALL:
        case ModalSize.INITIAL:
        default:
          return theme.spacings.unit(128);
      }
    })(),
  },
  [theme.breakpointsMediaQuery.up('lg')]: {
    maxHeight: (() => {
      switch (size) {
        case ModalSize.FULLSCREEN:
          return '';
        case ModalSize.BIG:
          return `calc(100vh - ${theme.spacings.unit(12)})`;
        default:
          return `calc(100vh - ${theme.spacings.unit(40)});`;
      }
    })(),
    width: (() => {
      switch (size) {
        case ModalSize.FULLSCREEN:
          return '100vw';
        case ModalSize.BIG:
          return theme.spacings.unit(256);
        case ModalSize.MEDIUM:
          return theme.spacings.unit(192);
        case ModalSize.SMALL:
        case ModalSize.INITIAL:
        default:
          return theme.spacings.unit(128);
      }
    })(),
  },
}));
