import React, { ReactNode } from 'react';

import { ModalTitleStyled } from './ModalTitle.style';
import { ModalStyleType } from '../../../models/enum';

export interface IProps {
  title: string | ReactNode | any;
  styleType?: ModalStyleType;
}

const ModalTitle: React.FC<IProps> = ({ title, styleType }) => {
  return <ModalTitleStyled styleType={styleType}>{title}</ModalTitleStyled>;
};

export default ModalTitle;
