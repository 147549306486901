import { styled } from '@mui/material';
import { CloseIcon } from '../../../assets';
import { ModalSize, ModalStyleType, ModalType } from '../../../models/enum';

interface ModalThemedProps {
  theme: any;
  type?: ModalType;
  size?: ModalSize;
  styleType?: ModalStyleType;
}

export const ModalHeaderStyled = styled('div')(
  ({ theme }: ModalThemedProps) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacings.unit(6)}`,
    width: '100%',
    justifyContent: 'space-between',
  })
);

export const Container = styled('div')(({ theme, type }: ModalThemedProps) => ({
  position: 'sticky',
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
  top: '0',
  marginBottom: `-${theme.spacings.unit(1 / 4)}`,
  zIndex: '1102',
  '&& span': {
    whiteSpace: 'normal',
  },
  backgroundColor: 'inherit',
  ...(type === ModalType.FULLSCREEN && {
    backgroundColor: 'unset',
  }),
}));

const CloseComponent = ({
  type,
  ...props
}: {
  onClick?: () => void;
  type?: ModalType;
  styleType?: ModalStyleType;
}) => {
  return (
    <span>
      {type === ModalType.FULLSCREEN && <span {...props}>×</span>}
      {type !== ModalType.FULLSCREEN && <CloseIcon {...props} size={'1.2em'} />}
    </span>
  );
};

export const CloseStyled = styled(CloseComponent, {
  shouldForwardProp: (prop) => !['styleType'].includes(prop as string),
})(({ theme, type, styleType }: ModalThemedProps) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacings.unit(1),
  color: (() => {
    switch (styleType) {
      default:
        return theme.colors.primary;
      case ModalStyleType.mobileFilters:
        return theme.colors.modalSecondaryText;
    }
  })(),
  fontSize: '40px',
  ...(type === ModalType.FULLSCREEN && {
    color: theme.colors.white,
    padding: '0px',
    fontSize: '40px',
    fontWeight: 'bold',
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
    },
  }),
}));

export const StyledDivider = styled('div')(({ theme }: ModalThemedProps) => ({
  borderTop: `1px solid ${theme.colors.drawerDivider}`,
}));
