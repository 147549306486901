import { styled } from '@mui/material';

export const ModalFooterStyled = styled('div')(({ theme, align }) => ({
  display: 'flex',
  gap: theme.spacings.unit(2),
  justifyContent: align,
  padding: `${theme.spacings.unit(4)} ${theme.spacings.unit(6)}`,
}));

export const Container = styled('div')(({ theme }) => ({
  position: 'sticky',
  bottom: '-1px',
  zIndex: '1102',
}));

export const StyledDivider = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.drawerDivider}`,
}));
