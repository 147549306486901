import { ModalSize, ModalStyleType, ModalType } from '../../../models/enum';
import Modal from '../modal/Modal';
import ModalContainer from '../modal-container/ModalContainer';
import ModalBody from '../modal-body/ModalBody';
import ModalHeader from '../modal-header/ModalHeader';
import ModalFooter from '../modal-footer/ModalFooter';
import React from 'react';
import ModalTitle from '../modal-title/ModalTitle';

export interface ModalWrapperProps {
  show: boolean;
  size?: ModalSize;
  type?: ModalType;
  styleType?: ModalStyleType;
  hideModal: () => void;
  children: any;
  showDefaultHeader?: boolean;
}

const ModalWrapper = ({
  show,
  type,
  size,
  hideModal,
  children,
  showDefaultHeader = true,
  styleType,
}: ModalWrapperProps) => {
  const handleOnClose = () => {
    hideModal();
  };

  const newProps = (i: number) => {
    return {
      onClose: handleOnClose,
      type: type,
      size: size,
      key: i,
    };
  };

  return (
    <Modal
      show={show}
      onClose={handleOnClose}
      styleType={styleType}
      size={size || ModalSize.SMALL}
    >
      <ModalContainer
        onClose={handleOnClose}
        type={type || ModalType.PROMPT}
        size={size || ModalSize.SMALL}
        styleType={styleType || ModalStyleType.contactUs}
      >
        {showDefaultHeader && (
          <ModalHeader
            type={type}
            typeHeader="text"
            onClose={handleOnClose}
          ></ModalHeader>
        )}
        {children?.map
          ? children?.map((child: any, i: number) =>
            React.cloneElement(child, newProps(i))
          )
          : React.cloneElement(children, newProps(0))}
      </ModalContainer>
    </Modal>
  );
};

ModalWrapper.Footer = ModalFooter;
ModalWrapper.Body = ModalBody;
ModalWrapper.Header = ModalHeader;
ModalWrapper.Title = ModalTitle;

export default ModalWrapper;
